import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

// ...GatsbyImageSharpFluid
const query = graphql`
  {
    file(relativePath: { eq: "asesoria_dentro_2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Texto = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <article>
      <Image fluid={fluid} className="asesoria-img"></Image>
      <p>
        <strong>Asesoría Guelbenzu Uralde </strong>está formada por un{" "}
        <strong>equipo multidisciplinar</strong> de expertos en todas las áreas
        más relevantes para la <strong>gestión de una empresa</strong>, como son
        el área{" "}
        <strong>mercantil, fiscal y tributaria, contable, laboral</strong>...
      </p>
      <p>
        Además, contamos con<strong> abogadas especializadas</strong>, por lo
        que podemos resolver cuestiones de carácter jurídico.
      </p>
      <p>
        Si estás <strong>emprendiendo un nuevo negocio</strong> o te vas a
        establecer como <strong>profesional</strong>, te ayudaremos a dar todos
        los pasos necesarios para que pueda elegir la mejor figura jurídica para
        tu empresa e iniciar todos los trámites de{" "}
        <strong>constitución y alta en el Registro Mercantil</strong>, si fueran
        necesarios.
      </p>
      <p>
        Una vez en funcionamiento, desde{" "}
        <strong>Asesoría Guelbenzu Uralde</strong> te podemos ayudar con el día
        a día en la <strong>gestión</strong> de todos los asuntos de la empresa
        desde la contabilidad, hasta las obligaciones tributarias, así como la
        confección de las cuentas anuales.
      </p>
      <p>
        Nos encargaremos de la presentación de todos los{" "}
        <strong>modelos oficiales de Hacienda</strong> para cumplir con tus
        obligaciones tributarias, pero, además, te asesoremos sobre la
        fiscalidad más conveniente para tu empresa, de forma que tengas la{" "}
        <strong>tributación más favorable</strong>
      </p>
      <p>
        En ocasiones, la empresa puede beneficiarse de{" "}
        <strong>bonificaciones, ayudas y subvenciones</strong>. Por ello,
        también te mantendremos al día sobre cualquier ayuda de la que puedas
        beneficiarte.{" "}
      </p>
      <p>
        Además, podemos llevar tu contabilidad y tributos tanto desde nuestras
        oficinas, como, en caso de que así lo requieras,{" "}
        <strong>desplazarnos donde lo necesites</strong>.
      </p>{" "}
      <p>
        En el <strong>área laboral</strong>, desde Asesoría Guelbenzu Uralde te
        ayudaremos a llevar al día toda la <strong>gestión de personal</strong>.
        En ese sentido nos encargaremos de todas las operaciones necesarias como
        son el <strong>alta y baja </strong>de los trabajadores en la Seguridad
        Social, el cálculo y confección de <strong>nóminas</strong> mensuales,
        la redacción de todo tipo de <strong>contratos</strong>, modificaciones
        de contratos etc. Nuestros expertos en legislación laboral también
        resolverán cualquier duda legal que tenga con respecto a la gestión del
        personal, especialmente en casos de desavenencia con un trabajador,
        sanciones, despidos, asistencia a actos de conciliación laboral y
        juicios, etc…
      </p>
      <p>
        En el equipo de Asesoría Guelbenzu Uralde tienes a tu disposición{" "}
        <strong>abogadas expertas</strong> que podrán ayudarte con el
        asesoramiento legal que precises en diversas áreas, como por ejemplo:
      </p>
      <p>
        <strong>Revisión y redacción de todo tipo de contratos</strong>: desde{" "}
        <strong>contratos mercantiles</strong> para su empresa hasta otro tipo
        de contratos como pueden ser otro tipo de contratos como los de{" "}
        <strong>compraventa</strong> o de<strong> arriendo</strong>. Revisaremos
        toda la documentación para redactar un contrato que se ajuste a sus
        necesidades e intereses.
      </p>{" "}
      <p>
        <strong>Reclamación de impagados</strong>: llevaremos a cabo esta
        gestión intentado que la resolución sea lo más rápida y favorable para
        usted.
      </p>{" "}
      <p>
        <strong>Tramitación de herencias</strong>: le ayudaremos con todos los
        trámites relacionados con la aceptación de la herencia, así como la
        obtención de la declaración de herederos. Asimismo, también con el
        cumplimiento de las obligaciones fiscales, a través del pago del
        Impuesto de Sucesiones.
      </p>{" "}
      <p>
        En Asesoría Uralde ofrecemos nuestros servicios no solo a aquellas
        empresas que lo necesiten, también a{" "}
        <strong>profesionales autónomos </strong>y <strong>particulares</strong>{" "}
        que, por ejemplo, tenga dudas respecto a sus obligaciones tributarias o
        deseen encargar la realización de la Declaración de la Renta a
        profesionales.
      </p>{" "}
      <p>
        ¿Necesita una asesoría en San Sebastian?
        <strong> Confíe en expertos </strong>con décadas de experiencia.
      </p>{" "}
      <p></p>
    </article>
  )
}

export default Texto
