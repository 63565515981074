import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Texto from "../components/Texto"
import Location from "../components/Location"
import SEO from "../components/SEO"

// ...GatsbyImageSharpFluid
const query = graphql`
  {
    file(relativePath: { eq: "juzgado.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const About = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Layout>
      <SEO
        title="Asesoria"
        description="Asesoría laboral en San Sebastian. Está formada por un equipo de abodagos expertos en todas las áreas más relevantes para la gestión de una empresa, como son el área mercantil, fiscal y tributaria, contable, laboral, declaracion de la renta, altas y bajas en la secguridad social, etc.."
      />
      <div className="about-hero">
        <div className="about-hero-wrap">
          <div className="about-hero-title-wrap">
            <h2 className="about-title">Su Asesoría en Donostia,</h2>
            <h2 className="about-subtitle">en Paseo Duque de Mandas 13</h2>
          </div>
          <Image fluid={fluid} className="about-hero-img"></Image>
        </div>
      </div>
      <section className="about-page">
        <div className="section-center about center">
          <Texto></Texto>
          <Title title="Nuestra ubicación" />
          <Location></Location>
        </div>
      </section>
    </Layout>
  )
}

export default About
