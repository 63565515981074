import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

// ...GatsbyImageSharpFluid
const query = graphql`
  {
    file(relativePath: { eq: "asesoria_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Location = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <div class="about-location">
      <div class="about-horario-wrapper">
        <div class="about-horario">
          <h3>horario de invierno</h3>
          <p>
            Del 21 de septiembre al 20 de junio <br /> Lunes a viernes
          </p>
          <p>08:00 a 15:30 horas</p>
          <p>
            Los miercoles tambien estamos por la tarde de:
            <br /> 16:30 a 18:00 horas
          </p>
        </div>
        <div class="about-horario">
          <h3>horario de verano</h3>
          <p>
            Del 21 de junio al 20 de septiembre
            <br /> Lunes a viernes
          </p>
          <p>08:00 a 15:30 horas</p>
        </div>
        <div class="about-horario">
          <h3>Direccion</h3>
          <p>
            Paseo Duque de Mandas 13 – Bajo Dcha
            <br />
            20012 San Sebastian
          </p>
          <p></p>
          <p>Tlf: 943 273 058</p>
        </div>
      </div>
      <div class="about-maps-wrap">
        <a
          href="https://www.google.com/maps/place/Asesoria+Guelbenzu+Uralde/@43.3193897,-1.974124,15z/data=!4m2!3m1!1s0x0:0xfd883055698a3706?sa=X&ved=2ahUKEwiQx_D8jvCAAxXU2gIHHe7vA0YQ_BJ6BAhGEAA&ved=2ahUKEwiQx_D8jvCAAxXU2gIHHe7vA0YQ_BJ6BAhQEAg"
          className="maps-link"
          target="_blank"
          rel="noreferrer"
        >
          <Image fluid={fluid} className="maps-img"></Image>
        </a>
      </div>
    </div>
  )
}

export default Location
